import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {constructArrayParams} from '../services/construct_array_params';
import {message} from '../services/shared';
import {I18n} from '../translation';
import {emailValidation} from '../services/authentications/email_service';
import {setQuantity} from '../services/carts/update_quantity';
import {freeFieldValidation} from '../services/authentications/free_field_service';

export default class extends Controller {

  filterData = [];
  query = '';
  selectedIndex = -1;
  amount;

  connect() {
    document.getElementById(`amount-${this.element.dataset.variantId}`)?.click();
  }

  filterCard(event) {
    if (event.target.checked) {
      this.filterData.push(event.target.value);
    } else {
      this.filterData.splice(this.filterData.indexOf(event.target.value), 1);
    }
    this.filterCards();
  }

  async filterCards() {
    const response = await fetch(`/search/filter-gift-card?q=${this.query}&${constructArrayParams(this.filterData, 'filter')}`);
    if (response.status === 200) {
      const text = await response.text();
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('gift_cards_list').replaceWith(vDom.children[0]);
    } else {
      popSnackbar('error', 'Failed to filter');
    }
  }

  search(event) {
    this.query = event.target.value;
    this.filterCards();
  }

  amountSelected(event) {
    const dataset = event.target.dataset;
    this.amount = dataset.amount;
    this.selectedIndex = dataset.id;
    this.resetSelectAmount(event);
    event.currentTarget.classList.add('sk-btn--secondary');
    if (!JSON.parse(event.target.dataset.type)) {
      (+dataset.stock < 1) ? document.getElementById('add-to-cart-btn').setAttribute('disabled', true)
        : document.getElementById('add-to-cart-btn').removeAttribute('disabled');
    }
  }

  amountEntered(event) {
    this.amount = event.currentTarget.value;
    this.selectedIndex = 'custom';
    this.resetSelectAmount(event);
    event.currentTarget.classList.add('sk-btn--secondary');
  }

  resetSelectAmount(event) {
    const allAmount = JSON.parse(event.target.dataset.allData);
    allAmount.forEach((res) => {
      document.getElementById(`amount-${res.id}`).classList.remove('sk-btn--secondary');
    });
  }

  addToCart(event) {
    const type = event.currentTarget.dataset.type;
    if (!!this.amount?.length) {
      const form = document.getElementById('addCartForm');
      const formData = new FormData(form);
      formData.append('amount', this.amount);
      formData.append('variant_id', this.selectedIndex);
      formData.append('type', type);

      fetch('/carts', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: formData
      }).then((response) => response.json())
        .then((data) => {
          if (data.in_cart) {
            message(I18n[window.currentLocale]['already_in_cart'], 'error');
          } else {
            document.getElementById("cart-dom").innerHTML = data.items
            this.setQuantity('add', 1);
            message(I18n[window.currentLocale]['product_added_cart'], 'success');
          }
        });
    } else {
      message(I18n[window.currentLocale]['fill_required_fields'], 'error');
    }
  }

  setQuantity(key, qty) {
    let count = parseInt(document.getElementById('cart_quantity').innerHTML) || parseInt(document.getElementById('cart_quantity_mobile').innerHTML);
    if (key === 'add') {
      document.getElementById('cart_quantity').innerHTML = count + qty;
      document.getElementById('cart_quantity_mobile').innerHTML = count + qty;
    } else {
      document.getElementById('cart_quantity_mobile').innerHTML = count - qty;
    }
  }
}
