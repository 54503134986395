import {Controller} from 'stimulus';

export default class extends Controller {
  inputId;


  connect() {
    let initialValue = this.element.dataset.initialValue;
    this.inputId = this.element.dataset.inputid;
    document.getElementById(this.inputId).value = initialValue;
  }

  selectOption(event) {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let selectedValue = event.currentTarget.dataset.value;
    let selectedActualValue = event.currentTarget.dataset.actual_value;
    document.getElementById(this.inputId).value = selectedValue;
    fetch(
      `/search/filter-gift-card?filter_by=${selectedActualValue}&type=${urlParams.get('type')}`
    ).then(response => {
        response.text().then(text => {
            document.getElementById('gift-card-container').innerHTML = text;
          }
        );
      }
    );
  }
}
