import {Controller} from 'stimulus';
import {UiService} from '../services/ui_service';

export default class extends Controller {
  connect() {
    let product = JSON.parse(this.element.dataset.product);

    // eslint-disable-next-line no-undef
    dataLayer.push({ecommerce: null});
    dataLayer.push(
      {
        event: "select_item",
        ecommerce: {
          currency: 'CHF',
          item_list_id: 'product-details',
          item_list_name: 'Product Details',
          items: product,
          value: product.reduce((sum, product) => sum + product.price, 0)
        }
      }
    );

    // eslint-disable-next-line no-undef
    dataLayer.push({ecommerce: null});
    dataLayer.push(
      {
        event: "view_item",
        ecommerce: {
          currency: 'CHF',
          value: product.reduce((sum, product) => sum + product.price, 0),
          items: product
        }
      }
    );
  }
}
