import {Controller} from 'stimulus';
import flatpickr from 'flatpickr';
import {SharedApiService} from '../services/api/shared-api_service';

export default class extends Controller {
  filters = {
    customerType: [],
    startDate: '',
    endDate: '',
    brand: [],
    status: [],
    internalCategory: [],
    itemCount: {
      operator: '',
      value: ''
    },
    payableAmount: {
      operator: '',
      value: ''
    },
    promotion: [],
    purchasePrice: {
      operator: '',
      value: ''
    },
    attribute: []
  };
  timeout;


  connect() {
    this.dateTime();
  }

  dateTime() {
    flatpickr('#order_filter_start_date', {
      dateFormat: 'd.m.Y'
    });

    flatpickr('#order_filter_end_date', {
      dateFormat: 'd.m.Y'
    });
  }

  setCheckboxFilter(event) {
    const dataset = event.currentTarget.dataset;
    event.currentTarget.checked ? this.addArrayFilter(dataset.value, dataset.type)
      : this.removeArrayFilter(dataset.value, dataset.type);
  }

  byStartDate(event) {
    this.filters.startDate = event.currentTarget.value;
  }

  byEndDate(event) {
    this.filters.endDate = event.currentTarget.value;
  }

  setOperationFilterOperator(event) {
    const dataset = event.currentTarget.dataset;
    const targetFilterDom = document.getElementById(dataset.targetInputId);
    targetFilterDom.value = event.currentTarget.innerText;
    this.filters[dataset.type].operator = dataset.value;
  }

  setOperationFilterValue(event) {
    const dataset = event.currentTarget.dataset;
    this.filters[dataset.type].value = event.currentTarget.value;
  }

  addArrayFilter(id, field) {
    !this.filters[field].includes(id) ? this.filters[field].push(id) : '';
  }

  removeArrayFilter(id, field) {
    this.filters[field].includes(id) ? this.filters[field].splice(this.filters[field].indexOf(id), 1) : '';
  }

  async showNestedFilters(event) {
    event.preventDefault();
    [...event.currentTarget.closest('ul').querySelectorAll('a')].forEach(element => element.classList.remove('bg-blue/[0.08]'));
    event.currentTarget.classList.add('bg-blue/[0.08]');
    const dataset = event.currentTarget.dataset;
    const url = `/admin/${dataset.type === 'internalCategory' ? 'internal-category-build' : 'options'}/add_child_filter/${dataset.id}?selected_ids=${this.filters[dataset.type]}&data_controller=order-filter`;
    new SharedApiService().fetchReplace(url, dataset.targetFrameId).then();
  }

  applyFilter(state) {
    clearTimeout(this.timeout);
    const useAbleState = typeof state === 'string' ? state : undefined;
    let element = document.getElementById('order_listing_component');
    let orderSort = this.application.getControllerForElementAndIdentifier(element, 'order-sort');
    const query = document.getElementById('search-fld').value;
    const params = `&q=${query}&filters=${JSON.stringify(this.filters)}&sort_type=${orderSort.sortType || ''}&sort_value=${orderSort.sortValue || ''}&state=${useAbleState || this.orderState() || 'all'}`;
    let url = `/admin/search/filter-orders?${params}`;
    this.timeout = setTimeout(() => {
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('order-listing').innerHTML = text;
        });
      });
    },500);
  }

  async resetAndApplyFilter(state) {
    await this.resetFilterContent();
    this.applyFilter(state);
  }

  async resetFilterContent() {
    let url = '/admin/filter/filter-content?type=order';
    await new SharedApiService().fetchReplace(url, 'filter_content_component');
    this.connect();
    this.resetFilterFields();
  }

  orderState() {
    const states = ['all', 'order_placed', 'ready_for_dispatch', 'in_transit', 'delivered', 'canceled', 'complete', 'returned'];
    for (let i in states) {
      if (window.location.href.includes(states[i])) return states[i];
    }
  }

  resetFilterFields() {
    this.filters = {
      customerType: [],
      startDate: '',
      endDate: '',
      brand: [],
      status: [],
      internalCategory: [],
      itemCount: {
        operator: '',
        value: ''
      },
      payableAmount: {
        operator: '',
        value: ''
      },
      promotion: [],
      purchasePrice: {
        operator: '',
        value: ''
      },
      attribute: []
    };
  }
}
