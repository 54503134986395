import {Controller} from 'stimulus';

export default class extends Controller {

  sortType;
  search(query) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let element = document.getElementById('orderslider');
      let orderFilter = this.application.getControllerForElementAndIdentifier(element, 'order-filter');
      let url = `/admin/search/filter-orders?q=${query}&sort_type=${this.sortType}&filters=${JSON.stringify(orderFilter.filters)}&state=all`;
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('order-listing').innerHTML = text;
        });
      });
    }, 500);
  }

  inputSearch(event) {
    let query = event.currentTarget.value;
    this.search(query);
  }

  buttonSearch() {
    let query = document.getElementById('search_input').value;
    this.search(query);
  }
}
