import {Controller} from 'stimulus';

export default class extends Controller {
  addGiftCardPreset() {
    if (document.getElementById('physical_gift_card').classList.contains('active')) {
      document.getElementById('physical').click();
    } else {
      document.getElementById('digital').click();
    }
  }
}
