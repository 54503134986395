import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {previewImage} from '../services/templates/products/preview/preview-components';
import {FormValidationService} from '../services/authentications/form_validation_service';
import {pluck} from '../helpers/custom_methods';
import {InternalCategoryService} from '../services/internal-category_service';

export default class extends Controller {
  static targets = ['status', 'productStatus'];
  activeLocale = 'en';
  allLocale = ['en', 'de', 'fr'];
  selectedSports;
  uploadedProductImages;
  uploadedCroppedImages;
  uploadedVariantImages;
  variantOptions;
  productVariants = [];
  internalCategoryValues = [];
  scopeId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
  mainForm = window.location.pathname.includes('new') ? document.getElementById('new_product') : document.getElementById(`edit_product_${this.scopeId}`);
  pre_order_config_attributes = {
    active: false,
    expected_stock: 0,
    expected_start_date: '',
    expected_end_date: '',
  };

  preventFormSubmit(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  switchLocale(event) {
    let scope = event.currentTarget.dataset.scope;

    this.activeLocale = event.currentTarget.dataset.locale;

    let targetLocale = event.currentTarget.dataset.locale;

    this.changeContent(`${scope}-dom`, targetLocale, event);
  }

  changeContent(domId, targetLocale, event) {
    let targetDom = document.getElementById(domId);

    [...targetDom.children].forEach(child => {
      if (!child.classList.contains(`${targetLocale}_fields`)) {
        child.classList.add('hidden');
      }
    }
    );

    let scope = event.currentTarget.dataset.scope;
    let localeDom = targetDom.querySelector(`.${targetLocale}_fields`);
    localeDom.classList.remove('hidden');
    let localeChips = document.querySelectorAll(`.locale-chips-${scope}`);
    localeChips.forEach(chip => {
      if (chip.dataset.locale !== targetLocale) {
        chip.classList.remove('sk-language-active', 'active-locale');
        chip.classList.add('sk-language-inactive');
      }
    });
    localeChips.forEach(chip => {
      if (chip.dataset.locale === targetLocale) {
        chip.classList.remove('sk-language-inactive');
        chip.classList.add('sk-language-active');
      }
    });
  }

  toggleProductStatus(event) {
    let checked = event.target.checked;

    this.statusTarget.value = checked ? 'active' : 'inactive';

    if (checked) {
      this.productStatusTarget.classList.remove('text-warning');
      this.productStatusTarget.classList.add('text-success');
      this.productStatusTarget.innerHTML = I18n[window.currentLocale]['active_product'];
    } else {
      this.productStatusTarget.classList.remove('text-success');
      this.productStatusTarget.classList.add('text-warning');
      this.productStatusTarget.innerHTML = I18n[window.currentLocale]['inactive_product'];
    }
  }

  duplicateAll() {
    let exceptActiveLocale = this.allLocale.filter(locale => ![this.activeLocale].includes(locale));
    [...document.querySelectorAll(`.${this.activeLocale}`)]
      .filter(input => input.id.includes('product_name') || input.id.includes('product_description'))
      .forEach((input) => {

        if (input?.id?.includes('product_name')) {
          exceptActiveLocale.forEach(locale => {
            let element = document.getElementById(`product_name_${locale}`);
            element && (element.value = input?.value);
          });
        }

        if (input?.id?.includes('product_description')) {
          exceptActiveLocale.forEach(locale => {
            let element = document.getElementById(`product_description_${locale}`);
            element && (element.value = input?.value);
          });
        }
      });

    this.copyRichText(exceptActiveLocale);

    this.removeCopyValidator();
    popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
  }

  copyRichText(exceptActiveLocale) {
    const quills = {
      en: this.accessLocaleQuill('en'),
      de: this.accessLocaleQuill('de'),
      fr: this.accessLocaleQuill('fr')
    };
    const content = quills[this.activeLocale].getContents().ops;
    exceptActiveLocale.forEach(locale => {
      quills[locale].setContents(content);
    });
  }

  accessLocaleQuill(locale) {
    const richTextControllerElement = document.getElementById(`rich-text-controller-element-${locale}`);
    const richTextEditorController = this.application.getControllerForElementAndIdentifier(richTextControllerElement, 'rich-text-editor');
    return richTextEditorController.quill;
  }

  removeCopyValidator() {
    const errorInfo = document.getElementById('language-switcher').querySelector('.text-error');
    if (errorInfo) {
      errorInfo.remove();
    }
  }

  callCreate(e) {
    e.preventDefault();
    this.element.disbaled = true;
    document.getElementById('pc-form-submit-btn').classList.remove('hidden');
    const additionalInformationElement = document.getElementById('additional-product-information');
    const productInfoController = this.application.getControllerForElementAndIdentifier(additionalInformationElement, 'additional-info');

    this.formulateParams();

    if (e.currentTarget.dataset.radix) document.getElementById('draft-product').value = true; // saving as draft

    let formData = new FormData(this.mainForm);
    const modifiedData = new FormData();

    for (const [key, value] of formData.entries()) {
      if (key.includes('localized_products_attributes') && (key.includes('taxon') || key.includes('taxonomy') || key.includes('product_type'))) {
        const elements = document.querySelectorAll(`[name="${key}"]`);
        elements.forEach((element) => {
          modifiedData.append(key, element.dataset.value);
        });
      } else {
        modifiedData.append(key, value);
      }
    }
    const element = document.getElementById('new_product');
    const productConfig = this.application.getControllerForElementAndIdentifier(element, 'product-configurator-stepper');
    const tags = productConfig.ribbon_ids.map(value => value.id);
    if (modifiedData.get('draft') === 'true') {
      const valid = this.validateDraft();
      //TODO: UI Translation
      if (!valid) {
        popSnackbar('error', 'Please fill basic details before saving as draft');
        return;
      }
    }

    modifiedData.append('images_attributes', JSON.stringify(this.uploadedProductImages));
    modifiedData.append('cropped_images', JSON.stringify(this.uploadedCroppedImages));
    modifiedData.append('internal_categories_values', JSON.stringify(this.internalCategoryValues));
    modifiedData.append('ribbon_ids', JSON.stringify(tags));
    modifiedData.append('product[product_informations_attributes]', JSON.stringify(productInfoController.actualData));
    modifiedData.append('product[cac_enable]', document.getElementById('click-collect').checked);

    if (document.getElementById('no_variants').checked) {
      modifiedData.append('product[cac_enable]', document.getElementById('no-variants-cac-toggle').checked);
      modifiedData.append('pre_order_config_attributes[active]', document.getElementById('enable-preorder').checked);
      modifiedData.append('pre_order_config_attributes[expected_stock]', document.getElementById('expected-stock').value);
      modifiedData.append('pre_order_config_attributes[expected_start_date]', document.getElementById('select_date_range_1').value.split(' to ')[0]);
      modifiedData.append('pre_order_config_attributes[expected_end_date]', document.getElementById('select_date_range_1').value.split(' to ')[1]);
      modifiedData.append('product[limit_stock]', document.getElementById('p-limit').checked);
      modifiedData.append('product[limit_stock_count]', document.getElementById('stock-novariant').value);
      modifiedData.append('cac_stock', document.getElementById('noVariantInput').value);
      modifiedData.append('cac_intermediate_stock', document.getElementById('noVariantInput').value);
      modifiedData.append('product[pre_order]', document.getElementById('enable-preorder').checked);
    }
    this.productVariants.forEach((variant, index) => {
      if (variant.id) {
        modifiedData.append(`product_variants[${index}][id]`, variant?.id);
      }
      modifiedData.append(`product_variants[${index}][sku]`, variant.sku);
      modifiedData.append(`product_variants[${index}][threshold]`, variant.threshold_input);
      modifiedData.append(`product_variants[${index}][barcode]`, variant.barcode);
      modifiedData.append(`product_variants[${index}][weight]`, variant.weight);
      modifiedData.append(`product_variants[${index}][height]`, variant.height);
      modifiedData.append(`product_variants[${index}][width]`, variant.width);
      modifiedData.append(`product_variants[${index}][depth]`, variant.depth);
      modifiedData.append(`product_variants[${index}][cac_stock]`, variant.cac_stock);
      modifiedData.append(`product_variants[${index}][cac_intermediate_stock]`, variant.cac_stock);
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][active]]`,
        formData.getAll('product[pre_order]').includes('true') ? variant.pre_order : 'false'
      );
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][expected_stock]]`,
        variant.expected_stock
      );
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][expected_start_date]]`,
        variant.expected_date.split(' to ')[0]
      );
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][expected_end_date]]`,
        variant.expected_date.split(' to ')[1]
      );
      variant.options.forEach(item => {
        modifiedData.append(`product_variants[${index}][options][]`, JSON.stringify(item));
      });
      modifiedData.append(`product_variants[${index}][image_urls]`, JSON.stringify([variant.img]));
    });


    fetch('/admin/product_configurator', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: modifiedData
    }).then(response => response.json())
      .then(data => {
        if (+data.status === 200) {
          popSnackbar('notice', data.message);
          setTimeout(() => {
            window.location.href = '/admin/product_configurator';
          }, 2500);
        } else {
          popSnackbar('error', data.message);
        }
      });
  }

  callUpdate(e) {
    e.preventDefault();
    this.element.disbaled = true;
    document.getElementById('pc-form-submit-btn').classList.remove('hidden');
    const additionalInformationElement = document.getElementById('additional-product-information');
    const productInfoController = this.application.getControllerForElementAndIdentifier(additionalInformationElement, 'additional-info');
    this.formulateParams();

    if (e.currentTarget.dataset.radix) document.getElementById('draft-product').value = true; // saving as draft

    let formData = new FormData(this.mainForm);
    const modifiedData = new FormData();

    for (const [key, value] of formData.entries()) {
      if (key.includes('localized_products_attributes') && (key.includes('taxon') || key.includes('taxonomy') || key.includes('product_type'))) {
        const elements = document.querySelectorAll(`[name="${key}"]`);
        elements.forEach((element) => {
          modifiedData.append(key, element.dataset.value);
        });
      } else {
        modifiedData.append(key, value);
      }
    }

    const element = document.getElementById(this.element.closest('form').id);
    const productConfig = this.application.getControllerForElementAndIdentifier(element, 'product-configurator-stepper');
    const tags = productConfig.ribbon_ids.map(value => value.id);

    if (modifiedData.get('draft') === 'true') {
      const valid = this.validateDraft();
      //TODO: UI Translation
      if (!valid) {
        popSnackbar('error', 'Please fill basic details before saving as draft');
        return;
      }
    }


    modifiedData.append('images_attributes', JSON.stringify(this.uploadedProductImages));
    modifiedData.append('cropped_images', JSON.stringify(this.uploadedCroppedImages));
    modifiedData.append('internal_categories_values', JSON.stringify(this.internalCategoryValues));
    modifiedData.append('ribbon_ids', JSON.stringify(tags));
    modifiedData.append('product[product_informations_attributes]', JSON.stringify(productInfoController.actualData));
    modifiedData.append('product[cac_enable]', document.getElementById('click-collect').checked);

    if (document.getElementById('no_variants').checked) {
      modifiedData.append('product[cac_enable]', document.getElementById('no-variants-cac-toggle').checked);
      modifiedData.append('pre_order_config_attributes[active]', document.getElementById('enable-preorder').checked);
      modifiedData.append('pre_order_config_attributes[expected_stock]', document.getElementById('expected-stock').value);
      modifiedData.append('pre_order_config_attributes[expected_start_date]', document.getElementById('select_date_range_1').value.split(' to ')[0]);
      modifiedData.append('pre_order_config_attributes[expected_end_date]', document.getElementById('select_date_range_1').value.split(' to ')[1]);
      modifiedData.append('product[limit_stock]', document.getElementById('p-limit').checked);
      modifiedData.append('product[limit_stock_count]', document.getElementById('stock-novariant').value);
      modifiedData.append('cac_stock', document.getElementById('noVariantInput').value);
      modifiedData.append('cac_intermediate_stock', document.getElementById('noVariantInput').value);
      modifiedData.append('product[pre_order]', document.getElementById('enable-preorder').checked);
    }

    this.productVariants.forEach((variant, index) => {
      if (variant.id) {
        modifiedData.append(`product_variants[${index}][id]`, variant?.id);
      }
      modifiedData.append(`product_variants[${index}][sku]`, variant.sku);
      modifiedData.append(`product_variants[${index}][threshold]`, variant.threshold_input);
      modifiedData.append(`product_variants[${index}][barcode]`, variant.barcode);
      modifiedData.append(`product_variants[${index}][weight]`, variant.weight);
      modifiedData.append(`product_variants[${index}][height]`, variant.height);
      modifiedData.append(`product_variants[${index}][width]`, variant.width);
      modifiedData.append(`product_variants[${index}][depth]`, variant.depth);
      modifiedData.append(`product_variants[${index}][cac_stock]`, variant.cac_stock);
      modifiedData.append(`product_variants[${index}][cac_intermediate_stock]`, variant.cac_stock);
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][active]]`,
        modifiedData.getAll('product[pre_order]').includes('true') ? variant.pre_order : 'false'
      );
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][expected_stock]]`,
        variant.expected_stock
      );
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][expected_start_date]]`,
        variant.expected_date.split(' to ')[0]
      );
      modifiedData.append(
        `product_variants[${index}][pre_order_configs_attributes[][expected_end_date]]`,
        variant.expected_date.split(' to ')[1]
      );
      variant.options.forEach(item => {
        modifiedData.append(`product_variants[${index}][options][]`, JSON.stringify(item));
      });
      modifiedData.append(`product_variants[${index}][image_urls]`, JSON.stringify([variant.img]));
    });

    let productId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];

    fetch(`/admin/product_configurator/${productId}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: modifiedData
    }).then(response => response.json())
      .then(data => {
        if (+data.status === 200) {
          popSnackbar('notice', data.message);
          setTimeout(() => {
            window.location.href = '/admin/product_configurator';
          }, 2500);
        } else {
          popSnackbar('error', data.message);
        }
      });
  }

  validateDraft() {
    const form = document.getElementById('basic-details-dom').querySelector(`.${this.activeLocale}_fields`);
    const validationService = new FormValidationService([form]);
    return validationService.validateForm();
  }

  previewProduct() {
    let uploadedImage = document.getElementById('product-images').querySelectorAll('img');
    let previewImageCollection = document.getElementById('preview-img-collection');

    // Display main preview image if uploaded
    if (uploadedImage.length !== 0) {
      document.getElementById('main_preview_image').src = uploadedImage[0].src;

      if (uploadedImage.length > 1) {
        previewImageCollection.innerHTML = '';

        uploadedImage.forEach((image, index) => {
          const tmpDom = document.createElement('div');
          tmpDom.innerHTML = previewImage(image.src, index);
          previewImageCollection.appendChild(tmpDom.firstChild);
        });
      }
    } else {
      previewImageCollection.innerHTML = 'Add images to preview';
    }
    document.getElementById('preview-description').innerHTML = document.querySelector('#product_description_editor .ql-editor').innerHTML;
  }


  formulateParams() {
    this.getSelectedSports();
    this.getProductImages();
    this.getVariantOptions();
    this.getProductVariants();
    this.getInternalCategory();

    document.getElementById('sport_products').value = JSON.stringify(this.selectedSports);
    // document.getElementById('product-images-field').value = JSON.stringify(this.uploadedProductImages);
    document.getElementById('product-option-values-hash').value = JSON.stringify(this.variantOptions);
    // document.getElementById('product-variants-hash').value = JSON.stringify(this.productVariants);
  }

  getSelectedSports() {
    let selectChipController = this.application.getControllerForElementAndIdentifier(this.mainForm, 'chip-selector');

    this.selectedSports = selectChipController.selectedChips; // send as comma separated stri
  }

  getProductImages() {
    let element1 = document.getElementById('image_uploader');
    let cropperController = this.application.getControllerForElementAndIdentifier(element1, 'product-image-cropper');
    this.uploadedCroppedImages = this.processImages(cropperController.productImages || [], JSON.parse(this.mainForm.dataset.croppedImages || '[]'));
    this.uploadedProductImages = JSON.parse(this.mainForm.dataset.productImages || '[]');
    this.uploadedVariantImages = JSON.parse(this.mainForm.dataset.variantImages || '[]');
  }

  processImages(cropped_image, image) {
    cropped_image.forEach(cropImg => {
      image.forEach((img, index) => {
        if (img != null) {
          if (cropImg.original_url === img.original_url) {
            image.splice(index, 1);
          }
        }
      });
    });
    return [...cropped_image, ...image] || '[]';
  }

  getVariantOptions() {
    const isEdit = location.href.includes('edit');
    if (isEdit) {
      this.variantOptions = JSON.parse(this.mainForm.dataset.optionValues);
    } else {
      let variantOptionsController = this.application.getControllerForElementAndIdentifier(this.mainForm, 'pro-options');
      this.variantOptions = variantOptionsController.dataParam;
    }
  }

  getProductVariants() {
    let pVariant = document.getElementById('product-variants').children;
    for (let i = 0; i < pVariant.length; i += 2) {

      let variantImage = {};
      for (let j = 0; j < this.uploadedVariantImages.length; j++) {
        let variantRowId = pVariant[i].querySelector('._img').id.replace('image', 'row'); // variant-row-id after replacing
        if (Object.keys(this.uploadedVariantImages[j]).includes(variantRowId)) {
          variantImage = this.uploadedVariantImages[j][variantRowId];
          break;
        }
      }

      let variantParams = {
        'id': pVariant[i]?.dataset?.uniqId || null,
        'sku': pVariant[i].querySelector('.sku').value,
        'barcode': pVariant[i].querySelector('.barcode').value,
        // 'img': pVariant[i]?.querySelector('._img').src,
        'img': variantImage?.url ? variantImage : null,
        'options': this.createVariant(pVariant[i]?.querySelector('.variant-options').innerHTML),
        // 'initial_stock': pVariant[i]?.querySelector('.initial-stock-input').value,
        'threshold_input': pVariant[i]?.querySelector('.threshold-input').value,
        'weight': pVariant[i + 1]?.querySelector('.weight-input').value,
        'height': pVariant[i + 1]?.querySelector('.height-input').value,
        'width': pVariant[i + 1]?.querySelector('.width-input').value,
        'depth': pVariant[i + 1]?.querySelector('.depth-input').value,
        'cac_stock': pVariant[i + 1]?.querySelector('.cac-stock-input').value,
        'cac_intermediate_stock': pVariant[i + 1]?.querySelector('.cac-stock-input').value,
        'pre_order': pVariant[i + 1]?.querySelector('.pre-order').value,
        'expected_stock': pVariant[i + 1]?.querySelector('.expected-stock').value,
        'expected_date': pVariant[i + 1]?.querySelector('.expected-date').value
      };

      this.productVariants.push(variantParams);
    }
  }

  getInternalCategory() {
    const internalCategoryIds = new InternalCategoryService().filteredIds;
    this.internalCategoryValues =
            internalCategoryIds.map(id => document.getElementById(id).value).filter(value => !!value);
  }

  createVariant(values) {
    let option_values = values.split('/');
    let option_values_hash = [];

    option_values.forEach(value => {
      value = value.trim();
      let option_type = '';
      let index = '';
      getOt(this, value);

      function getOt(_this, value) { // breaking nested loops became complicated without this
        Object.keys(_this.variantOptions['en']).forEach((optionType) => { // en because no translation in admin portal
          if (pluck(_this.variantOptions['en'][optionType], 'name').includes(value)) {
            option_type = optionType;
            index = pluck(_this.variantOptions['en'][optionType], 'name').indexOf(value);
            return true;
          }
        });
      }

      let tmpHash = {};
      let localized_option_value = [];
      Object.keys(this.variantOptions).forEach(locale => {
        let obj = {};

        if (option_type === 'Color') {
          tmpHash['name'] = option_type;
          tmpHash['value'] = this.variantOptions['en'][option_type][index].value;
        } else {
          tmpHash['name'] = option_type;
          tmpHash['value'] = this.variantOptions['en'][option_type][index].name;
        }

        obj['name'] = option_type === 'Color' ? this.variantOptions[locale][option_type][index].value : this.variantOptions[locale][option_type][index].name;
        obj['locale'] = locale;
        localized_option_value.push(obj);

      });
      tmpHash['localized_option_values_variant_attributes'] = localized_option_value;

      if (Object.keys(tmpHash).length !== 0) option_values_hash.push(tmpHash);
    });

    return option_values_hash;
  }
}
