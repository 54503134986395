import {Controller} from 'stimulus';
import {I18n} from '../translation';

export default class extends Controller {
  currentStep = 1;
  secondStepper = document.getElementById('stepper2-icons');
  thirdStepper = document.getElementById('stepper3-icons');

  selectStepper(event) {
    this.selectStep(event.currentTarget.dataset.step);
    this.previousStep();
  }

  selectContinue(e) {
    const shippingLabel = this.thirdStepper.dataset.shipping || I18n[window.currentLocale]['shipping'];
    this.selectStep(this.currentStep + 1);
    this.secondStepper.innerHTML = '<i class="material-icons-outlined !text-[16px] text-white state-icon">done</i>';
    this.thirdStepper.innerHTML = `<div class="sk-checkout-stepper__step-status flex items-center justify-center w-[16px] h-[16px] rounded-full my-0 mx-auto">
                                       <div class="sk-checkout-stepper__step-dot bg-gray-400 p-[4px] rounded-full"></div>
                                   </div>
                                   <p class="absolute ${shippingLabel === I18n[window.currentLocale]['shipping'] ? '-left-[8px] ml-[4px]' : '-left-[20px]'} text-[14px] next">${shippingLabel}</p>`;
    let items = JSON.parse(e.currentTarget.dataset.items);
    // eslint-disable-next-line no-undef
    dataLayer.push({ecommerce: null});
    dataLayer.push(
      {
        event: 'begin_checkout',
        ecommerce: {
          currency: 'CHF',
          value: +document.getElementById('total_amount').innerText.split(' ')[1],
          items: items
        }
      }
    );
  }

  previousStep() {
    const shippingLabel = this.thirdStepper.dataset.shipping || I18n[window.currentLocale]['shipping'];
    this.selectStep(this.currentStep);
    if (document.getElementById('guest_checkout_email')) {
      document.getElementById('guest_checkout_email').value = '';
    }
    this.secondStepper.innerHTML = '<div class="sk-checkout-stepper__step-dot bg-gray-400 p-[4px] rounded-full"></div>';
    this.thirdStepper.innerHTML = `<div class="sk-checkout-stepper__step-status flex items-center justify-center w-[16px] h-[16px] !bg-gray-400 rounded-full my-0 mx-auto">
                                      <div class="sk-checkout-stepper__step-dot bg-gray-400 rounded-full"></div>
                                   </div>
                                   <p class="absolute ${shippingLabel === I18n[window.currentLocale]['shipping'] ? '-left-[8px] ml-[4px]' : '-left-[20px]'} text-[14px] previous">${shippingLabel}</p>`;
  }

  selectStep(step) {
    const childElements = document.getElementById('cart-stepper-content').children;
    for (let i = 1; i <= childElements.length; i++) {
      if (step == i) {
        childElements[i - 1].classList.remove('hidden');
        window.scrollTo(0, 0);
      } else {
        childElements[i - 1].classList.add('hidden');
      }
    }
  }
}