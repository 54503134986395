import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    window.addEventListener("scroll", this.scrollFunction.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollFunction.bind(this));
  }

  scrollFunction() {
    const element = document.getElementById('scrollTopBtn');
    if (element) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        element.style.display = "flex";
      } else {
        element.style.display = "none";
      }
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}