import {Controller} from 'stimulus';

export default class extends Controller {
  // NO USAGE.
  productOptionTypes = JSON.parse(this.element.dataset.productOptionTypes);

  selectOption(event) {
    const dataSet = event?.currentTarget?.dataset;
    if (dataSet?.copyValueOnChange && JSON.parse(dataSet.copyValueOnChange)){
      this.copyValueToOtherLocales(event);
      return;
    }
    const { targetFieldId, value } = this.targetMembers(event);
    const targetField = document.getElementById(targetFieldId);

    targetField.value = value;
    targetField.dataset.value = event.currentTarget.dataset?.optionId;

    this.productOptionTypes.includes(value) ? this.customAppend(value) : null;
  }

  copyValueToOtherLocales(event) {
    const { targetFieldId, value } = this.targetMembers(event);
    const parts = targetFieldId.split('_');
    const baseId = parts.slice(0, -1).join('_');
    const locales = ['en', 'de', 'fr'];
    const targetIDs = locales.map(locale => `${baseId}_${locale}`);
    targetIDs.forEach(id => {
      const targetField = document.getElementById(id);
      if (targetField) {
        targetField.value = value;
        targetField.dataset.value = event.currentTarget.dataset?.optionId;
      }
    });

    this.productOptionTypes.includes(value) ? this.customAppend(value) : null;
  }

  targetMembers(event) {
    return {
      targetFieldId: event.currentTarget.dataset?.targetField,
      value: event.currentTarget.dataset?.value
    };
  }

  customAppend(value) {
    let addValueCtrl = document.getElementById('add-option-value-ctrl');
    addValueCtrl.dataset.optionTypeTarget = value;

    document.querySelectorAll('*[id^=option-type]').forEach(el => {
      el.id.includes(value) ? el.classList.remove('hidden') : el.classList.add('hidden');
    });
  }
}
